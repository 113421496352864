<template>
  <div class="UpgradeHistory">
    <div class="filter-pane">
      <label>选择车辆</label>
      <SelectGroupTree style="width:200px"
        placeholder="选择上级车组"
        @on-select="onSelect"
        :multipleProp="{ noGroup: true }"
        @clear="clearClick"
        @on-vehicle="onVehicle"
        @dbClick="onGroups" />

      <label>升级结果</label>
      <el-select style="width:100px"
        v-model="searchParams.taskStatus"
        size="mini"
        placeholder="请选择">
        <el-option v-for="item in selectOptions"
          :label="item.label"
          :value="item.value"
          :key="item.label"></el-option>
      </el-select>

      <label>开始时间</label>
      <el-date-picker v-model="searchParams.startTime"
        type="date"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        placeholder="选择日期"
        :picker-options="pickerOptions"></el-date-picker>
      <label>结束时间</label>
      <el-date-picker v-model="searchParams.endTime"
        type="date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        :picker-options="pickerOptions"></el-date-picker>

      <el-button class="search-btn"
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="getList">查询</el-button>
    </div>

    <div class="table-pane">
      <el-table :data="tableData"
        stripe
        style="width: 100%"
        height="94%">
        <el-table-column type="index"
          width="50"
          label="序号"></el-table-column>
        <el-table-column prop="plate"
          label="设备名称"
          width="180"></el-table-column>
        <el-table-column prop="terminalNo"
          label="终端号"
          width="180"></el-table-column>
        <el-table-column prop="taskStatus"
          label="升级状态">
          <template v-slot="{row}">{{selectOptions.map(p=>p.label)[row.taskStatus + 1]}}</template>
        </el-table-column>
        <el-table-column prop="targetFirmwareVersion"
          label="目标固件版本号"></el-table-column>
        <el-table-column prop="taskBeginTime"
          label="任务开始时间">
          <template v-slot="{row}">{{row.taskBeginTime |formatDate}}</template>
        </el-table-column>
        <el-table-column prop="taskEndTime"
          label="任务结束时间">
          <template v-slot="{row}">{{row.taskEndTime |formatDate}}</template>
        </el-table-column>
        <!-- <el-table-column prop="taskEndTime" label="任务结束时间">
          <template
            v-slot="{row}"
          >{{ row.taskEndTime |formatDate}}
          </template>
        </el-table-column> -->
        <el-table-column prop="operUserName"
          label="创建用户名"></el-table-column>
        <el-table-column prop="manufacturers"
          label="制造商"></el-table-column>
        <el-table-column prop="createTime"
          label="创建时间">
          <template v-slot="{row}">{{ row.createTime |formatDate}}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination :total="tablePage.total"
        :current-page="tablePage.pageNumber"
        @current-change="changePage"
        :page-size="tablePage.pageSize"
        :pager-count="7"></el-pagination>
    </div>
  </div>
</template>

<script>
import { getHistoryList } from '@/api/getHome.js';
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import { parseTime } from "@/utils/formatDate";

export default {
  name: 'UpgradeHistory',
  components: {
    SelectGroupTree
  },
  data() {
    return {
      searchParams: {
        vehicleIds: [],
        groupIds: [],
        taskStatus: '', //任务状态：1-失败，0-未开始，1-执行中，2-完成，3-超时
        startTime: '',
        endTime: '',
      },
      addVisible: false, // 添加弹窗状态
      tablePage: { //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      tableData: [],

      selectOptions: [ // 状态
        { label: '失败', value: -1 },
        { label: '未开始', value: 0 },
        { label: '执行中', value: 1 },
        { label: '完成', value: 2 },
        { label: '超时', value: 3 },
        { label: '全部', value: '' },
      ],
      pickerOptions: {
        disabledDate(time) {
          let date = new Date();
          // date.setFullYear(date.getFullYear()+2);
          // date.setDate(date.getDate()-1);
          return (time.getTime() > date.getTime());
        }
      },
    };
  },
  filters: {
    formatDate(val) {
      return val ? parseTime(val) : '无';
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /** 获取升级包list */
    async getList() {
      if (new Date(this.searchParams.startTime) > new Date(this.searchParams.endTime)) {
        this.$message({
          message: '请选择正确的开始和结束时间',
          type: 'warning'
        });
        return false;
      }
      if ((this.searchParams.startTime || this.searchParams.endTime) && (!this.searchParams.startTime || !this.searchParams.endTime)) {
        this.$message({
          message: '请选择完整的时间范围',
          type: 'warning'
        });
        return false;
      }
      let searchParams = JSON.parse(JSON.stringify(this.searchParams));
      if (searchParams.startTime) {
        searchParams.startTime = searchParams.startTime + ' 00:00:00';
        searchParams.endTime = searchParams.endTime + ' 23:59:59';
      }
      try {
        const result = await getHistoryList({
          ...searchParams,
          pageIndex: this.tablePage.pageNumber,
          pageSize: this.tablePage.pageSize
        });

        if (result.flag !== 1) return this.$message.error(result.msg);

        // this.$message.success(result.msg);
        this.tableData = result.rows;
        this.tablePage.total = result.total;

      } catch ({ msg }) {
        console.log(msg);
        this.$message.error(msg);
      }
    },
    // 模态框关闭
    closeDialog(model) {
      this.addVisible = model;
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.getList();
    },

    // 清除车辆
    clearClick() {
      this.searchParams.vehicleIds = [];
      this.searchParams.groupIds = [];
    },

    onVehicle(node) {
      this.searchParams.vehicleIds = [];
      this.searchParams.vehicleIds.push(node.vehicleId);
    },

    onGroups(node) {
      this.searchParams.groupIds = [];
      this.searchParams.groupIds.push(node.groupId);
    },

    //车组车辆搜索框选择
    onSelect(node) {
      this.searchParams.groupIds = [];
      this.searchParams.vehicleIds = [];
      const { type } = node;
      if (type === 1) return this.searchParams.groupIds.push(node.groupId);
      if (type === 2) return this.searchParams.vehicleIds.push(node.vehicleId);
    },
  }
}
</script>

<style lang="scss" scoped>
.UpgradeHistory {
  width: 100%;
  height: 100%;
  position: relative;
}
.filter-pane {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > label {
    margin: 0 8px;
    color: #666666;
    font-size: 12px;
  }
  & > .el-input {
    width: 130px;
  }
}
.search-btn {
  margin: 0 10px;
}
.button-pane {
  position: absolute;
  right: 0;
}
.table-pane {
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 25px;
  box-shadow: 0px 0px 10px 1px rgba(66, 120, 201, 0.2);
  border-radius: 8px;
}
.el-table {
  border-radius: 8px;
}
.el-pagination {
  text-align: right;
  margin: 5px 0;
}
.iconClass {
  font-size: 24px;
  color: #4278c9;
}
::v-deep .el-table__row--striped {
  background-color: #dce8fc;
}
</style>